import React from 'react';
import { orderBy } from 'lodash';
import { ELSInlineLoader } from '@els/els-ui-common-react';
import { ELSTooltip } from '../../components/els.components';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { Messages } from '../../translations/message.models';

export type AiChatUserMaterialsProps = {
  ebookIsbnsAndTitles: Record<string, string>; // string[][];
  openVitalSource: (isbn: string, page: number) => void;
  messages: Messages;
  isLoading: boolean;
}

export const AiChatUserMaterials = (props: AiChatUserMaterialsProps) => {

  const {
    ebookIsbnsAndTitles,
    openVitalSource,
    messages,
    isLoading
  } = props;

  const userHasSherpathAiIsbns = Object.keys(ebookIsbnsAndTitles).length > 0;

  const renderBookLink = (isbn: string, title: string) => {
    return (
      <button
        title={title}
        type="button"
        className="u-els-anchorize u-els-debuttonize c-els-ai-chat__user-materials-title"
        onClick={() => {
          openVitalSource(isbn, 0);
        }}
      >
        {title}
      </button>
    );
  };

  const ebookAccessDisplay = (
    <div>
      <div className="u-els-bold u-els-color-n9 u-els-padding-bottom-1x">
        {userHasSherpathAiIsbns ? messages.SHERPATH_AI_MATERIALS_ACCESS : messages.SHERPATH_AI_NO_USER_MATERIALS}
      </div>
      <div className="c-els-ai-chat__user-materials u-els-clearfix">
        {userHasSherpathAiIsbns
          && orderBy(Object.entries(ebookIsbnsAndTitles), ([, title]) => title, 'asc')
            .map(([isbn, title]) => {
              if (!title) {
                return null;
              }
              return (
                <FlexLayout key={isbn}
                            modifiers={[FlexLayoutModifier.GUTTERS]}
                            classes={['u-els-padding-bottom-1o2']}>
                  <FlexItem modifiers={[FlexLayoutModifier.GROW]} classes={['c-els-ai-chat__user-materials-title-parent']}>
                    {renderBookLink(isbn, title)}
                  </FlexItem>
                  <FlexItem>
                    <span className="u-els-color-n7">{messages.SHERPATH_AI_EBOOK_LABEL}</span>
                  </FlexItem>
                </FlexLayout>
              );
            })}
      </div>
    </div>
  );

  return (
    <ELSTooltip
      id="userMaterialsTooltip"
      content={isLoading
        ? (
          <div>
            Loading your materials...
            <ELSInlineLoader />
          </div>
        )
        : ebookAccessDisplay}
      dialogAriaLabel={messages.SHERPATH_AI_MATERIALS_LABEL}
    >
      <span
        className="u-els-debuttonize u-els-anchorize"
      >
        {messages.SHERPATH_AI_MATERIALS_LABEL}
      </span>
    </ELSTooltip>
  );
};
